<template>
  <div class="userCenterBox">
    <div class="card-box">
      <div class="card-hd">
        <div class="card-hd-left">
          我的信息
        </div>
        <div class="card-hd-right">
          <Refresh @refreshCall="refreshHandle"></Refresh>
        </div>
      </div>
      <div class="card-bd">
        <div class="card-bd-alone">
          <div class="user-img-box">
            <img :src="baseData.userInfo.avatar ? baseData.userInfo.avatar : defaultImg" alt="" />
          </div>
          <div class="user-msg-box">
            <div class="user-item">
              用户名：
              <span>{{ baseData.userInfo.account }}</span>
            </div>
            <div class="user-item">
              用户ID：
              <span>{{ baseData.userInfo.id }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Refresh from '@/components/Refresh/index.vue'
import defaultImg from '@/assets/defaultImg.jpg'
import { onMounted, reactive } from 'vue'
import { getUserInfo } from '@/api/user'

const baseData = reactive({
  userInfo: {}
})

// 获取用户信息
const getUserInfoHandle = async (page) => {
  const res = await getUserInfo()
  baseData.userInfo = res
  console.error(res)
}

// 刷新事件
const refreshHandle = () => {
  getUserInfoHandle()
}

onMounted(() => {
  getUserInfoHandle()
})

</script>

<style lang="scss" scoped>
@import '~@/styles/variables.scss';
.userCenterBox {
  background: $menuBg;
  height: calc( 100vh - 50px);
  .card-box {
    margin-top: 0;
    .card-hd {
      &-left {
        .refreshBox {
          margin-left: 6px;
          cursor: pointer;
          &.isDisabled {
            cursor: no-drop;
          }
        }
      }
    }
  }
  .card-bd-alone {
    .user-img-box {
      width: 90px;
      height: 90px;
      border-radius: 100%;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 16px;
      cursor: pointer;
      img {
        width: 100%;
      }
    }
    .user-item {
      margin-bottom: 6px;
      color: $mainGreyLight;
      span {
        font-weight: bold;
        color: $mainBlack;
        font-size: 18px;
      }
    }
  }
}
</style>
