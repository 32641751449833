<template>
  <div class="storeBox reportListBox">
    <div class="card-box">
      <div class="card-hd">
        <div class="card-hd-left">
          报表列表
        </div>
        <div class="card-hd-right">
          <Refresh @refreshCall="getAllReportList"></Refresh>
        </div>
      </div>
    </div>

    <div class="handleLineBox">
      <!-- select -->
      <div class="baseSelect">
        <!-- 模糊搜索 -->
        <SearchBox
          :searchVal="baseData.searchVal"
          placeholder="请输入报表名称"
          @searchCall="searchHandle"
        ></SearchBox>
        <!-- 下拉选择 -->
        <SelectDown v-if="false"
          :selectVal="baseData.selectVal"
          :selectData="baseData.selectData"
          @selectDownCall="selectDownHandle"
        ></SelectDown>
        <!-- 弹窗 -->
        <el-button type="primary" icon="CirclePlus" @click="SimpleDialogHandle('addReport')">去新增报表</el-button>
      </div>
    </div>

    <!-- table -->
    <div class="baseTable">
      <!-- <el-scrollbar> -->
      <el-table
        v-loading="baseData.tableLoading"
        :data="baseData.tableListData"
        style="width: 100%;">
        <el-table-column
          v-for="(item, index) in baseData.tableHdData"
          :key="index"
          :prop="item.prop"
          :label="item.label"
          :min-width="item?.minWidth"
          :fixed="item.prop == 'handle' ? 'right' : item.prop == 'name' ? 'left' : false"
          align="center"
        >
          <template #default="scope">
            <div v-if="item.prop == 'handle'">
              <el-button size="small" type="primary" @click="lookReportAnalysisHandle(scope.row.id)">查看详情</el-button>
              <el-button size="small" type="danger" @click="delReportAnalysisHandle(scope.row.id)">删除</el-button>
            </div>
            <span v-else>
              {{ scope.row[item.prop] }}
            </span>
          </template>
        </el-table-column>
        <!-- table empty -->
        <template #empty>
          <div class="baseTableEmpty">
            <el-empty description="暂无报表">
              <el-button type="primary" icon="CirclePlus" @click="SimpleDialogHandle('addReport')">去新增报表</el-button>
            </el-empty>
          </div>
        </template>
      </el-table>
      <!-- </el-scrollbar> -->
    </div>

    <!-- pagination 分页 -->
    <div class="basePagination">
      <el-config-provider :locale="zhCn">
        <el-pagination
          v-model:current-page="baseData.currentPage"
          v-model:page-size="baseData.pageSize"
          :page-sizes="baseData.pageSizes"
          layout="total, sizes, prev, pager, next, jumper"
          :total="baseData.pageTotal"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </el-config-provider>
    </div>

    <!-- 弹窗 -->
    <div class="detailFormDialogBox">
      <el-dialog
          v-model="lookData.show"
          fullscreen
          align-center
          :close-on-click-modal="false"
          @close="lookData.show = false"
      >
        <div class="card-box">
          <div class="card-hd">
            <div class="card-hd-left">
              报表详情
            </div>
            <div class="card-hd-right">
            </div>
          </div>
          <div class="card-bd">
            <div class="goodsFormBox">
              <el-scrollbar>
                <div class="card-box">
                  <div class="card-hd">
                    <div class="card-hd-left">
                      报表发货状态详情
                    </div>
                    <div class="card-hd-right">
                    </div>
                  </div>
                  <div class="card-bd">
                    <div v-if="lookData.data?.length > 0"
                      class="height-auto-table"
                    >
                      <el-table
                        :data="lookData.data"
                        stripe
                        style="width: calc(100vw - 24px);">
                        <el-table-column
                          v-for="(item, index) in lookData.deconstructionHead"
                          :key="index"
                          :prop="item.prop"
                          :label="item.label + (item.unit ? '(' + item.unit + ')' : '')"
                          :fixed="item?.fixed"
                          :min-width="item?.width"
                          align="center"
                        >
                          <template #default="scope">
                            <template v-if="item.prop == 'orderNumDetail'">
                              <el-table-column
                                v-for="(itemChild, indexChild) in lookData.desconstructionHeadLevel2"
                                :key="indexChild"
                                :prop="itemChild.prop"
                                :label="itemChild.label"
                                :min-width="itemChild?.width"
                                align="center"
                              >
                                <template #default="scopeChild">
                                  <div class="status-table-clumn">
                                    <span>{{ scopeChild.row[itemChild.prop].orderNum }}单</span>
                                    <span>{{ scopeChild.row[itemChild.prop].moneySum }}元</span>
                                  </div>
                                </template>
                              </el-table-column>
                            </template>
                            <span v-else>
                              {{ scope.row[item.prop] }}
                            </span>
                          </template>
                        </el-table-column>
                      </el-table>
                    </div>
                  </div>
                </div>
                <div class="card-box">
                  <div class="card-hd">
                    <div class="card-hd-left">
                      报表发货状态详情
                    </div>
                    <div class="card-hd-right">
                    </div>
                  </div>
                  <div class="card-bd">
                    <div id="myChartLast" class="echart-box"></div>
                  </div>
                </div>
              </el-scrollbar>
            </div>
          </div>
        </div>
      </el-dialog>
    </div>

  </div>
</template>

<script setup>
import zhCn from 'element-plus/lib/locale/lang/zh-cn'
// eslint-disable-next-line
import SelectDown from '@/components/SelectDown/index.vue'
import SearchBox from '@/components/SearchBox/index.vue'
import Refresh from '@/components/Refresh/index.vue'
import { colorArr, createTimeBarTickEchart } from '@/utils/echart/echartSet.js'
import { nextTick, onMounted, reactive } from 'vue'
import { ElMessageBox } from 'element-plus'
import { reportAnalysisPageList, reportAnalysisDel, reportAnalysisDetail } from '@/api/report.js'
import { timeToDate } from '@/utils/unitTransform.js'
import { useRouter } from 'vue-router'

const router = useRouter()
const baseData = reactive({
  selectTimes: '',
  selectVal: 'Option2',
  selectData: [
    {
      value: 'Option1',
      label: 'Option1'
    },
    {
      value: 'Option2',
      label: 'Option2'
    }
  ],
  tableLoading: false,
  tableHdData: [
    { label: '模板名称', prop: 'table_name', minWidth: '200' },
    { label: '创建时间', prop: 'created_at', minWidth: '180' },
    { label: '操作', prop: 'handle', minWidth: '250' }
  ],
  currentPage: 1,
  pageSize: 100,
  pageTotal: 0,
  pageSizes: [100, 200, 300, 400],
  searchVal: ''
})
const lookData = reactive({
  data: null,
  show: false,
  baseOrderStatus: ['待付款', '已取消', '待发货', '未发货，退款成功', '已发货，待收货', '已发货，退款成功', '已收货', '已收货，退款成功'],
  deconstructionHead: [
    { label: '时间', prop: 'date', width: '100', fixed: true },
    { label: '销售额', prop: 'moneySum', unit: '元', width: '120', fixed: true },
    { label: '订单数', prop: 'orderNum', unit: '单', width: '100', fixed: true },
    { label: '客单价', prop: 'unitPrice', unit: '元', width: '100', fixed: true },
    { label: '订单详情', prop: 'orderNumDetail', width: '140' }
  ],
  headHouQin: [
    { label: '人工费用', prop: 'laborCost', unit: '元', width: '120' },
    { label: '物流费用', prop: 'logisticsCost', unit: '元', width: '120' },
    { label: '真实销售额', prop: 'todMoneySum', unit: '元', width: '120' },
    { label: '真实订单数', prop: 'todOrderNumber', unit: '单', width: '120' }
  ],
  headSkuCost: [
    { label: '商品成本', prop: 'todSkuCostSum', unit: '元', width: '120' },
    { label: '销售毛利', prop: 'todGrossMargin', unit: '元', width: '120' },
    { label: '毛利率', prop: 'todGrossMarginDot', width: '100' }
  ],
  headYunYing: [
    { label: '广告/扣款/打款等支出', prop: 'operPayment', unit: '元', width: '120' },
    { label: '利润', prop: 'operProfit', unit: '元', width: '120' },
    { label: '利润率', prop: 'operProfitDot', width: '120' },
    { label: '进宝佣金', prop: 'operJinbao', unit: '元', width: '120' },
    { label: '其他服务', prop: 'operOhterFuwu', unit: '元', width: '120' },
    { label: '技术服务', prop: 'operJishuFuwu', unit: '元', width: '120' },
    { label: '扣款', prop: 'operKoukuan', unit: '元', width: '120' },
    { label: '优惠券', prop: 'operYouhuiquan', unit: '元', width: '120' },
    { label: '好评支出', prop: 'operHaoping', unit: '元', width: '120' },
    { label: '其他支出', prop: 'operOtherPayment', unit: '元', width: '120' },
    { label: '推广费', prop: 'operTuiGuang', unit: '元', width: '120' }
  ],
  desconstructionHeadLevel2: [
    { label: '待付款', prop: '待付款', width: '100' },
    { label: '已取消', prop: '已取消', width: '100' },
    { label: '待发货', prop: '待发货', width: '100' },
    { label: '未发货，退款成功', prop: '未发货，退款成功', width: '150' },
    { label: '已发货，待收货', prop: '已发货，待收货', width: '150' },
    { label: '已发货，退款成功', prop: '已发货，退款成功', width: '150' },
    { label: '已收货', prop: '已收货', width: '150' },
    { label: '已收货，退款成功', prop: '已收货，退款成功', width: '150' }
  ]
})

// 搜索事件
const searchHandle = (type, searchBox) => {
  baseData.searchVal = type ? searchBox.searchVal : ''
  getReportAnalysisPageList()
}

// 分页相关
const handleSizeChange = (val) => {
  console.log(`${val} items per page`)
}
const handleCurrentChange = (val) => {
  console.log(`current page: ${val}`)
}
// 下拉选择相关
const selectDownHandle = (val) => {
  baseData.selectVal = val
}
// simpleDialog 弹窗唤醒事件
const SimpleDialogHandle = (type) => {
  router.push({ name: 'reportExcel', params: { event: type } })
}

// 获取模板分页列表
const getReportAnalysisPageList = async () => {
  const resData = await reportAnalysisPageList({
    template_name: baseData.searchVal,
    page: baseData.currentPage,
    page_size: baseData.pageSize
  })
  for (const i in resData.data) {
    const dataItem = resData.data[i]
    if (dataItem?.created_at) {
      dataItem.created_at = timeToDate(dataItem?.created_at)
    }
  }
  baseData.tableListData = resData.data
  baseData.pageTotal = resData.total
  console.log('getReportAnalysisPageList', resData)
}
// 查看详情接口
const lookReportAnalysisHandle = async (id) => {
  const res = await reportAnalysisDetail({
    record_id: id
  })
  const newData = []
  for (const i in res.reverse()) {
    Object.assign(res[i], JSON.parse(res[i].date_result))
    if (i === '0') {
      // 使用了后勤模板
      res[i]?.todOrderNumber && lookData.deconstructionHead.push(...lookData.headHouQin)
      // 使用了sku成本表
      res[i]?.todSkuCostSum && lookData.deconstructionHead.push(...lookData.headSkuCost)
      // 使用了运营费用模板
      res[i]?.operPayment && lookData.deconstructionHead.push(...lookData.headYunYing)
    }
    newData.push(res[i])
  }
  lookData.data = res
  lookData.show = true
  console.log('lookReportAnalysisHandle 编辑模板接口', id, res)
  await resDataSetChart(res)
}
// 删除模板事件 — 询问
const delReportAnalysisHandle = (id) => {
  ElMessageBox.confirm(
    '删除后该模板将不可再次使用，是否继续？',
    {
      confirmButtonText: '删除',
      cancelButtonText: '取消',
      type: 'warning'
    }
  ).then(() => {
    delApiHandle(id)
  }).catch(() => {
  })
}
// 删除模板事件 — 接口
const delApiHandle = async (id) => {
  await reportAnalysisDel({
    ids: [id]
  })
  getReportAnalysisPageList()
}

const getAllReportList = () => {
  getReportAnalysisPageList()
}

onMounted(() => {
  getAllReportList()
})

// 初始化柱状时间销量图
const resDataSetChart = (resData) => {
  nextTick(() => {
    const chartArr = []
    resData.map(item => {
      chartArr.push(JSON.parse(item.date_result))
    })
    createTimeBarTickEchart(
      chartArr,
      lookData.baseOrderStatus,
      colorArr,
      document.getElementById('myChartLast')
    )
  })
}
</script>

<style lang="scss" >
@import '~@/styles/variables.scss';
@import '~@/styles/mixin.scss';
@import '~@/styles/report/index.scss';
.reportListBox {
  .detailFormDialogBox {
    .el-dialog__body {
      padding: 20px 24px;
    }
    .echart-box {
      width: 100%;
      height: 500px;
    }
    .goodsFormBox {
      box-sizing: border-box;
      width: 100%;
      .height-auto-table {
        width: 100%;
      }
    }
  }
}
</style>
