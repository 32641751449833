<template>
  <div class="storeBox operatefeeBox">
    <div class="card-box">
      <div class="card-hd">
        <div class="card-hd-left">
          运营费用表列表
        </div>
        <div class="card-hd-right">
        </div>
      </div>
    </div>

    <div class="handleLineBox">
      <!-- select -->
      <div class="baseSelect">
        <!-- 模糊搜索 -->
        <SearchBox
          :searchVal="baseData.searchVal"
          placeholder="请输入运营费用表名称"
          @searchCall="searchHandle"
        ></SearchBox>
        <!-- 下拉选择 -->
        <SelectDown v-if="false"
          :selectVal="baseData.selectVal"
          :selectData="baseData.selectData"
          @selectDownCall="selectDownHandle"
        ></SelectDown>
        <el-button type="primary" icon="CirclePlus" @click="SimpleDialogHandle('operatefee')">新增运营费用表</el-button>
        <!-- 弹窗 Sku层 -->
        <div class="detailFormDialogBox">
          <el-dialog
              v-model="baseData.SimpleDialogShow"
              width="1140px"
              align-center
              :close-on-click-modal="false"
              @close="resetForm(ruleFormOperateRef)"
          >
            <div class="card-box">
              <div class="card-hd">
                <div class="card-hd-left">
                  {{ ruleFormOperate?.templateId ? '编辑' : '新增'}}运营费用表
                </div>
                <div class="card-hd-right">
                </div>
              </div>
              <div class="card-bd">
                <div class="goodsForm">
                  <el-tabs tab-position="left"
                    v-model="tabsHd.currentIndex"
                    @tab-click="tabsListHandle($event, tabsHd, baseData, goodsFormScoll)">
                    <el-tab-pane
                      v-for="item in tabsHd.list"
                      :key="item.name"
                      :label="item.title"
                      :name="item.name">
                      <template #label>
                        {{ item.title }}
                      </template>
                    </el-tab-pane>
                  </el-tabs>
                  <div class="goodsFormBox">
                    <el-scrollbar
                      @scroll="doScroll($event, tabsHd, baseData)"
                      ref="goodsFormScoll"
                      :always="true">
                      <el-form
                        ref="ruleFormOperateRef"
                        :model="ruleFormOperate"
                        status-icon
                      >
                        <div class="card-box" id="cardItem0">
                          <div class="card-hd">
                            <div class="card-hd-left">
                              基础信息
                            </div>
                            <div class="card-hd-right">
                            </div>
                          </div>
                          <div class="card-bd">
                            <div class="card-bd-item is-alone">
                              <el-form-item label="运营费用表名称"
                                prop="templateName"
                                :rules="rulesSkuCost.templateName"
                              >
                                <el-input v-model="ruleFormOperate.templateName"
                                  :min="0"
                                  maxlength="30"
                                  placeholder="运营费用表名称"
                                  show-word-limit
                                  clearable
                                  :disabled="ruleFormOperate?.templateId"
                                >
                                </el-input>
                              </el-form-item>
                            </div>
                            <div class="card-bd-item is-alone greylight2">
                              {{ ruleFormOperate.templateId ? '编辑状态下将为表名添加时间后缀：xxx*(2401010930)' : '' }}
                            </div>
                          </div>
                        </div>
                        <div class="card-box" id="cardItem1">
                          <div class="card-hd">
                            <div class="card-hd-left">
                              对账中心表
                              <a href="https://donkey.agreatthinking.com/excel/%E5%AF%B9%E8%B4%A6%E4%B8%AD%E5%BF%83%E8%A1%A8-%E6%A8%A1%E6%9D%BF.xlsx"
                                target="_blank" class="a-default greylight2"
                              >
                                点击下载对账中心表模板
                              </a>
                            </div>
                            <div class="card-hd-right card-right-btns">
                              <el-button
                                type="danger"
                                @click="operateTableRefDelete('duizhang')"
                                :disabled="!operateTableObj.selectedDuizhang"
                              >删除选中</el-button>
                              <el-button
                                type="primary"
                                icon="Upload"
                                @click="comHandleHandleUpload('duizhang')"
                              >上传对账中心表</el-button>
                              <el-button
                                type="primary"
                                icon="Upload"
                                :loading="!mainListen.btnSwitch"
                                @click="getWebviewDataHandle('duizhang')"
                              >获取对账中心表</el-button>
                              <el-button
                                type="primary"
                                icon="plus"
                                :loading="!mainListen.btnSwitch"
                                @click="addRowHandle('duizhang')"
                              >添加一行</el-button>
                            </div>
                          </div>
                          <div class="card-bd">
                            <div class="height-auto-table">
                              <el-table
                                :data="ruleFormOperate.list.duizhang"
                                stripe
                                style="width: 100%"
                                ref="duizhangTableRef"
                                highlight-current-row
                                @selection-change="operateTableRefHandle('duizhang', $event)"
                              >
                                <el-table-column type="selection" width="55" />
                                <el-table-column :prop="item" :label="item" v-for="(item, index) in baseData.callDuizhangRule" :key="index">
                                  <template #default="{ row, $index }">
                                    <el-form-item
                                      :prop="`list.duizhang.${$index}.${item}`"
                                      :rules="rulesSkuCost.list.duizhang[item]"
                                    >
                                      <template v-if="item === '日期'">
                                        <el-date-picker
                                          v-model="row[item]"
                                          type="date"
                                          placeholder="请选择日期"
                                          format="YYYY-MM-DD"
                                          value-format="YYYY-MM-DD"
                                          :shortcuts="baseData.shortCuts"
                                        />
                                      </template>
                                      <template v-else>
                                        <el-input
                                          v-if="item !== '退款'"
                                          type="number"
                                          v-model="row[item]"
                                          :min="0"
                                          :max="99999999"
                                          :placeholder="`${item}`"
                                          clearable
                                        ></el-input>
                                        <el-input
                                          v-else
                                          type="number"
                                          v-model="row[item]"
                                          :min="0"
                                          :max="99999999"
                                          :placeholder="'退款参考值'"
                                          clearable
                                        ></el-input>
                                      </template>
                                    </el-form-item>
                                  </template>
                                </el-table-column>
                                <template #empty>
                                  <el-empty description="对账中心表暂无内容"></el-empty>
                                </template>
                              </el-table>
                            </div>
                          </div>
                        </div>
                        <div class="card-box" id="cardItem2">
                          <div class="card-hd">
                            <div class="card-hd-left">
                              商品推广表
                              <a href="https://donkey.agreatthinking.com/excel/%E5%95%86%E5%93%81%E6%8E%A8%E5%B9%BF%E8%A1%A8-%E6%A8%A1%E6%9D%BF.xlsx"
                                target="_blank" class="a-default"
                              >
                                点击下载商品推广表模板
                              </a>
                            </div>
                            <div class="card-hd-right card-right-btns">
                              <el-button
                                type="danger"
                                @click="operateTableRefDelete('tuiguang')"
                                :disabled="!operateTableObj.selectedTuiguang"
                              >删除选中</el-button>
                              <el-button
                                type="primary"
                                icon="Upload"
                                @click="comHandleHandleUpload('tuiguang')"
                              >上传商品推广表</el-button>
                              <el-button
                                type="primary"
                                icon="Upload"
                                :loading="!mainListen.btnSwitch"
                                @click="getWebviewDataHandle('tuiguang')"
                              >获取商品推广表</el-button>
                              <el-button
                                type="primary"
                                icon="plus"
                                :loading="!mainListen.btnSwitch"
                                @click="addRowHandle('tuiguang')"
                              >添加一行</el-button>
                            </div>
                          </div>
                          <div class="card-bd">
                            <div class="height-auto-table">
                              <el-table
                                :data="ruleFormOperate.list.tuiguang"
                                stripe
                                style="width: 100%"
                                ref="tuiguangTableRef"
                                highlight-current-row
                                autocomplete="off"
                                @selection-change="operateTableRefHandle('tuiguang', $event)"
                              >
                                <el-table-column type="selection" width="55" />
                                <el-table-column :prop="item" :label="item" v-for="(item, index) in baseData.callTuiguangRule" :key="index">
                                  <template #default="{ row, $index }">
                                    <el-form-item
                                      :prop="`list.tuiguang.${$index}.${item}`"
                                      :rules="rulesSkuCost.list.tuiguang[item]"
                                    >
                                    <template v-if="item === '日期'">
                                        <el-date-picker
                                          v-model="row[item]"
                                          type="date"
                                          placeholder="请选择日期"
                                          format="YYYY-MM-DD"
                                          value-format="YYYY-MM-DD"
                                          :shortcuts="baseData.shortCuts"
                                        />
                                      </template>
                                      <template v-else>
                                        <el-input
                                          type="number"
                                          v-model="row[item]"
                                          :min="0"
                                          :max="99999999"
                                          :placeholder="`填写${item}`"
                                          clearable
                                        ></el-input>
                                      </template>
                                    </el-form-item>
                                  </template>
                                </el-table-column>
                                <template #empty>
                                  <el-empty description="商品推广表暂无内容"></el-empty>
                                </template>
                              </el-table>
                            </div>
                          </div>
                        </div>
                      </el-form>
                    </el-scrollbar>
                  </div>
                </div>
              </div>
            </div>
            <template #footer>
              <span class="dialog-footer" v-if="ruleFormOperate?.templateId">
                <el-button type="primary" @click="submitFormSkuCost(ruleFormOperateRef, 'edit')">
                  保存编辑
                </el-button>
              </span>
              <span class="dialog-footer" v-else>
                <el-button type="primary" @click="submitFormSkuCost(ruleFormOperateRef)">
                  保存
                </el-button>
              </span>
            </template>
          </el-dialog>
        </div>
      </div>
    </div>

    <!-- table -->
    <div class="baseTable">
      <!-- <el-scrollbar> -->
      <el-table
        v-loading="baseData.tableLoading"
        :data="baseData.tableListData"
        style="width: 100%;">
        <el-table-column
          v-for="(item, index) in baseData.tableHdData"
          :key="index"
          :prop="item.prop"
          :label="item.label"
          :min-width="item?.minWidth"
          :fixed="item.prop == 'handle' ? 'right' : item.prop == 'name' ? 'left' : false"
          align="center"
        >
          <template #default="scope">
            <el-tag
              v-if="item.prop == 'platform'"
              type="danger"
              disable-transitions
            >
              {{ scope.row.platform }}
            </el-tag>
            <div v-else-if="item.prop == 'handle'">
              <el-button size="small" type="primary" @click="editOperateFeeHandle(scope.row)">编辑</el-button>
              <el-button size="small" type="danger" @click="delOperateFeeHandle(scope.row.id)">删除</el-button>
            </div>
            <div v-else-if="item.prop == 'address'">
              {{ scope?.row[item.prop] ? scope.row[item.prop] : '未设置' }}
            </div>
            <span v-else>
              {{ scope.row[item.prop] }}
            </span>
          </template>
        </el-table-column>
        <!-- table empty -->
        <template #empty>
          <div class="baseTableEmpty">
            <el-empty description="暂无运营费用表">
              <el-button type="primary" icon="CirclePlus" @click="SimpleDialogHandle('operatefee')">新增运营费用表</el-button>
            </el-empty>
          </div>
        </template>
      </el-table>
      <!-- </el-scrollbar> -->
    </div>

    <!-- pagination 分页 -->
    <div class="basePagination">
      <el-config-provider :locale="zhCn">
        <el-pagination
          v-model:current-page="baseData.currentPage"
          v-model:page-size="baseData.pageSize"
          :page-sizes="baseData.pageSizes"
          layout="total, sizes, prev, pager, next, jumper"
          :total="baseData.pageTotal"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </el-config-provider>
    </div>

    <UploadExcel
      :onSuccess="excelCall"
      ref="UploadExcelCom"
      v-show="exlcelData?.header.length === 0"
    ></UploadExcel>

  </div>
</template>

<script setup>
import zhCn from 'element-plus/lib/locale/lang/zh-cn'
// eslint-disable-next-line
import UploadExcel from '@/components/UploadExcel/index.vue'
import SelectDown from '@/components/SelectDown/index.vue'
import SearchBox from '@/components/SearchBox/index.vue'
import { onMounted, reactive, watch, ref, nextTick } from 'vue'
import { skuCostList, operateFeePageList, operateFeeAdd, operateFeeDel, operateFeeDetail } from '@/api/report.js'
import { ElMessage, ElMessageBox } from 'element-plus'
import { timeToDate, jsonToNewName, excelToJsonDate } from '@/utils/unitTransform.js'
import { tabsListHandle, doScroll, getCardItemSite } from '@/utils/general.js'
import { useRoute } from 'vue-router'

const route = useRoute()
const UploadExcelCom = ref(null)
const exlcelData = reactive({
  header: [],
  results: []
})
const baseData = reactive({
  selectVal: 'Option2',
  selectData: [
    {
      value: 'Option1',
      label: 'Option1'
    },
    {
      value: 'Option2',
      label: 'Option2'
    }
  ],
  tableLoading: false,
  tableHdData: [
    { label: '运营费用表名称', prop: 'name', minWidth: '200' },
    { label: '创建时间', prop: 'created_at', minWidth: '180' },
    { label: '操作', prop: 'handle', minWidth: '250' }
  ],
  tableListData: [],
  currentPage: 1,
  pageSize: 100,
  pageTotal: 0,
  pageSizes: [100, 200, 300, 400],
  SimpleDialogShow: false,
  searchVal: '',
  callDuizhangRule: ['日期', '进宝佣金', '其它预估服务', '技术服务费', '扣款', '优惠券', '好评有礼金', '其他支出', '退款'],
  callTuiguangRule: ['日期', '推广费'],
  shortCuts: [
    {
      text: '今天',
      value: new Date()
    },
    {
      text: '昨天',
      value: () => {
        const date = new Date()
        date.setTime(date.getTime() - 3600 * 1000 * 24)
        return date
      }
    },
    {
      text: '上星期',
      value: () => {
        const date = new Date()
        date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
        return date
      }
    }
  ]
})
// 基础组件 事务 start
const goodsFormScoll = ref()
// tabs信息
// tabs sku信息
const tabsHd = reactive({
  currentIndex: 0,
  list: [
    { title: '基础信息', name: 0 },
    { title: '对账中心表', name: 1 },
    { title: '商品推广表', name: 2 }
  ]
})

// 搜索事件
const searchHandle = (type, searchBox) => {
  baseData.searchVal = type ? searchBox.searchVal : ''
  getOperateFeePageList()
}

// tabs currentIndex监听
watch(() => tabsHd.currentIndex, (newValue) => {
  console.log('watch 已触发', newValue)
})
watch(() => baseData.SimpleDialogShow, (newValue) => {
  newValue && getCardItemSite(tabsHd)
})

// 运营费用表表单 action
// 表单 信息
const ruleFormOperate = reactive({
  templateName: '', // 模板名称
  list: {
    duizhang: [],
    tuiguang: []
  }
})
// 表单 验证相关
const ruleFormOperateRef = ref()
// 表单 模板名校验规则
const checkOperateName = (rule, value, callback) => {
  if (!value) {
    return callback(new Error('请输入运营费用表名称'))
  } else {
    callback()
  }
}
const checkValueNull = (rule, value, callback) => {
  if (!value) {
    return callback(new Error('请填写输入项'))
  } else {
    callback()
  }
}
// 表单 校验规则
const rulesSkuCost = reactive({
  templateName: [{ required: true, validator: checkOperateName, trigger: 'blur' }],
  list: {
    duizhang: {
      // eslint-disable-next-line
      '日期': [{ required: true, validator: checkValueNull, trigger: 'submit' }],
      // eslint-disable-next-line
      '进宝佣金': [{ required: true, validator: checkValueNull, trigger: 'submit' }],
      // eslint-disable-next-line
      '其它预估服务': [{ required: true, validator: checkValueNull, trigger: 'submit' }],
      // eslint-disable-next-line
      '技术服务费': [{ required: true, validator: checkValueNull, trigger: 'submit' }],
      // eslint-disable-next-line
      '扣款': [{ required: true, validator: checkValueNull, trigger: 'submit' }],
      // eslint-disable-next-line
      '优惠券': [{ required: true, validator: checkValueNull, trigger: 'submit' }],
      // eslint-disable-next-line
      '好评有礼金': [{ required: true, validator: checkValueNull, trigger: 'submit' }],
      // eslint-disable-next-line
      '其他支出': [{ required: true, validator: checkValueNull, trigger: 'submit' }]
    },
    tuiguang: {
      // eslint-disable-next-line
      '日期': [{ required: true, validator: checkValueNull, trigger: 'submit' }],
      // eslint-disable-next-line
      '推广费': [{ required: true, validator: checkValueNull, trigger: 'submit' }]
    }
  }
})
// 表单 验证后提交表单
const submitFormSkuCost = (formEl, isEdit) => {
  if (!formEl) return
  formEl.validate((valid) => {
    if (valid) {
      console.log('提交!', ruleFormOperate)
      addOperatefeeHandle(ruleFormOperate, isEdit)
    } else {
      console.log('不能提交')
      ElMessage.error('请填写输入项')
      return false
    }
  })
}
// 表单 重置表单
const resetForm = (formEl, typeName) => {
  if (!formEl) return
  if (typeName) {
    ElMessageBox.confirm(
      `${typeName}后所有未保存的内容均会丢失，是否继续？`,
      {
        confirmButtonText: '继续',
        cancelButtonText: '取消',
        type: 'warning'
      }
    ).then(() => {
      formEl.resetFields()
    }).catch(() => {
    })
  } else {
    formEl.resetFields()
    clearEditObject()
  }
}

const clearEditObject = () => {
  ruleFormOperate.templateName = null
  ruleFormOperate.templateId = null
  ruleFormOperate.list = {
    duizhang: [],
    tuiguang: []
  }
}
// 运营费用表表单 end
// 基础组件 事务 end

// 分页相关
const handleSizeChange = (val) => {
  console.log(`${val} items per page`)
}
const handleCurrentChange = (val) => {
  console.log(`current page: ${val}`)
}
// 下拉选择相关
const selectDownHandle = (val) => {
  baseData.selectVal = val
}
// simpleDialog 弹窗唤醒事件
const SimpleDialogHandle = (type) => {
  baseData.SimpleDialogShow = true
}
// 获取新开页面数据事件
const getWebviewDataHandle = (type) => {
  switch (type) {
    case 'duizhang':
      localStorage.setItem('main-duizhang', null)
      listeningStorage('duizhang')
      break
    case 'tuiguang':
      localStorage.setItem('main-tuiguang', null)
      listeningStorage('tuiguang')
      break
  }
  console.log('获取新开页面数据事件,类型为：', type)
  localStorage.setItem('otherWinType', type)
  window.parent.postMessage({ getWebviewData: type }, '*')
}

// 获取SKU成本列表
const getSkuCostList = async () => {
  const res = await skuCostList({
    template_name: baseData.searchVal
  })
  console.log('getSkuCostList', res)
}
// 获取SKU成本分页列表
const getOperateFeePageList = async () => {
  const resData = await operateFeePageList({
    template_name: baseData.searchVal,
    page: baseData.currentPage,
    page_size: baseData.pageSize
  })
  for (const i in resData.data) {
    const dataItem = resData.data[i]
    if (dataItem?.created_at) {
      dataItem.created_at = timeToDate(dataItem?.created_at)
    }
  }
  baseData.tableListData = resData.data
  baseData.pageTotal = resData.total
  console.log('getOperateFeePageList', resData)
}
// 新增运营费用表接口
const addOperatefeeHandle = async (ruleForm, isEdit) => {
  const tempObj = {
    duizhang: [],
    tuiguang: []
  }
  if (ruleFormOperate?.list?.duizhang) {
    for (const i in ruleFormOperate.list.duizhang) {
      const duizhangItem = ruleFormOperate.list.duizhang[i]
      jsonToNewName(duizhangItem, '日期', 'date')
      jsonToNewName(duizhangItem, '进宝佣金', 'jinbao_yongjin')
      jsonToNewName(duizhangItem, '其它预估服务', 'other_fuwu')
      jsonToNewName(duizhangItem, '技术服务费', 'jishufuwu_fee')
      jsonToNewName(duizhangItem, '扣款', 'koukuan')
      jsonToNewName(duizhangItem, '优惠券', 'youhuiquan')
      jsonToNewName(duizhangItem, '好评有礼金', 'haoping_lijin')
      jsonToNewName(duizhangItem, '其他支出', 'other_zhichu')
      // jsonToNewName(duizhangItem, '退款', 'tuikuan')
      tempObj.duizhang.push(duizhangItem)
    }
  }
  if (ruleFormOperate?.list?.tuiguang) {
    for (const i in ruleFormOperate.list.tuiguang) {
      const tuiguangItem = ruleFormOperate.list.tuiguang[i]
      jsonToNewName(tuiguangItem, '日期', 'date')
      jsonToNewName(tuiguangItem, '推广费', 'tuiguang_fee')
      tempObj.tuiguang.push(tuiguangItem)
    }
  }
  // 如果是编辑状态，获取当前时间
  if (isEdit) {
    const nowTime = timeToDate(new Date().getTime() / 1000).substring(2)
    const regex = /-/g
    const regex1 = / /g
    const regex2 = /:/g
    const newStr = nowTime.replace(regex, '').replace(regex1, '').replace(regex2, '')
    ruleFormOperate.templateName = `${ruleFormOperate.templateName.split('*')[0]}*${newStr}`
  }
  await operateFeeAdd({
    template_name: ruleFormOperate.templateName,
    content: JSON.stringify(tempObj)
  })
  getOperateFeePageList()
  baseData.SimpleDialogShow = false
}
// 编辑模板接口
const editOperateFeeHandle = async (row) => {
  ruleFormOperate.templateName = row.name
  ruleFormOperate.templateId = row.id
  const resData = await operateFeeDetail({
    template_id: row.id
  })
  if (resData?.duizhang) {
    ruleFormOperate.list.duizhang = []
    for (const i in resData.duizhang) {
      const duizhangItem = resData.duizhang[i]
      jsonToNewName(duizhangItem, 'date', '日期')
      jsonToNewName(duizhangItem, 'jinbao_yongjin', '进宝佣金')
      jsonToNewName(duizhangItem, 'other_fuwu', '其它预估服务')
      jsonToNewName(duizhangItem, 'jishufuwu_fee', '技术服务费')
      jsonToNewName(duizhangItem, 'koukuan', '扣款')
      jsonToNewName(duizhangItem, 'youhuiquan', '优惠券')
      jsonToNewName(duizhangItem, 'haoping_lijin', '好评有礼金')
      jsonToNewName(duizhangItem, 'other_zhichu', '其他支出')
      resData.duizhang[i].tempId = resData.duizhang[i].id
      ruleFormOperate.list.duizhang.push(duizhangItem)
    }
  }
  if (resData?.tuiguang) {
    ruleFormOperate.list.tuiguang = []
    for (const i in resData.tuiguang) {
      const tuiguangItem = resData.tuiguang[i]
      jsonToNewName(tuiguangItem, 'date', '日期')
      jsonToNewName(tuiguangItem, 'tuiguang_fee', '推广费')
      resData.tuiguang[i].tempId = resData.tuiguang[i].id
      ruleFormOperate.list.tuiguang.push(tuiguangItem)
    }
  }
  SimpleDialogHandle()
}
// 删除模板事件 — 询问
const delOperateFeeHandle = (id) => {
  ElMessageBox.confirm(
    '删除后该运营费用表将不可再次使用，是否继续？',
    {
      confirmButtonText: '删除',
      cancelButtonText: '取消',
      type: 'warning'
    }
  ).then(() => {
    delApiHandle(id)
  }).catch(() => {
  })
}
// 删除模板事件 — 接口
const delApiHandle = async (id) => {
  await operateFeeDel({
    ids: [id]
  })
  getOperateFeePageList()
}

onMounted(() => {
  getSkuCostList()
  getOperateFeePageList()
})
watch(() => route.params, (newParams) => {
  if (newParams.event === 'addOperateFee') SimpleDialogHandle('operatefee')
}, {
  immediate: true
})

// 点击调用子组件的上传事件
const comHandleHandleUpload = (scene) => {
  UploadExcelCom.value && UploadExcelCom.value.handleUpload('', scene)
}
// excel组件回调
const excelCall = (resData, loadingInstance) => {
  loadingInstance.close()
  console.log('excel组件回调', resData, '场景为', resData.scene)
  if (resData.scene) {
    switch (resData.scene) {
      case 'duizhang':
        if (resData.header.length !== 8) {
          ElMessage.error('对账中心表头必须为"日期,进宝佣金,技术服务费,扣款,优惠券,好评有礼金,其它预估服务,其他支出"')
        } else {
          for (const i in resData.header) {
            if (baseData.callDuizhangRule.indexOf(resData.header[i]) === -1) {
              ElMessage.error('对账中心表头必须为"日期,进宝佣金,技术服务费,扣款,优惠券,好评有礼金,其它预估服务,其他支出"')
              return
            }
          }
          ruleFormOperate.list.duizhang = resData.results
          // excel日期转换
          for (const i in resData.results) {
            resData.results[i]['日期'] = excelToJsonDate(resData.results[i]['日期'])
            resData.results[i].tempId = timeToDate(new Date().getTime() / 1000).substring(2).replace(/-/g, '').replace(/ /g, '').replace(/:/g, '') + (Math.floor(Math.random() * (999999999 - 100000000 + 1)) + 100000000).toString().slice(-9)
          }
          ElMessage.success('对账中心表上传成功"')
        }
        break
      case 'tuiguang':
        if (resData.header.length !== 2) {
          ElMessage.error('商品推广表表头必须为日期，推广费')
        } else {
          for (const i in resData.header) {
            if (baseData.callTuiguangRule.indexOf(resData.header[i]) === -1) {
              ElMessage.error('商品推广表表头必须为日期，推广费')
              return
            }
          }
          ruleFormOperate.list.tuiguang = resData.results
          // excel日期转换
          for (const i in resData.results) {
            resData.results[i]['日期'] = excelToJsonDate(resData.results[i]['日期'])
            resData.results[i].tempId = timeToDate(new Date().getTime() / 1000).substring(2).replace(/-/g, '').replace(/ /g, '').replace(/:/g, '') + (Math.floor(Math.random() * (999999999 - 100000000 + 1)) + 100000000).toString().slice(-9)
          }
          ElMessage.success('商品推广表上传成功"')
        }
        break
    }
  }
}
// 监听localStorage值变化方法 start
const mainDuizhang = ref(localStorage.getItem('main-duizhang'))
const mainTuiguang = ref(localStorage.getItem('main-tuiguang'))
const mainListen = reactive({
  mainType: '',
  timeZone: null,
  tempSecond: 0,
  btnSwitch: true
})
const duizhangDataSet = (data) => {
  const now = new Date()
  const dateString = now.toISOString().split('T')[0].split('-')
  const dataObj = JSON.parse(data).val.reverse()
  dataObj.tempId = timeToDate(new Date().getTime() / 1000).substring(2).replace(/-/g, '').replace(/ /g, '').replace(/:/g, '') + (Math.floor(Math.random() * (999999999 - 100000000 + 1)) + 100000000).toString().slice(-9)
  for (const i in dataObj) {
    baseData.callDuizhangRule.map(item => {
      if (item !== '日期') {
        dataObj[i][item] = 0
      }
    })
    // 处理日期 - 针对对账中心
    if (dataObj[i]['日期'].split('-').length === 2) {
      const trueDateSplit = dataObj[i]['日期'].split('-')
      // 如果当前月份小于或等于数据月份
      if (Number(dateString[1]) < Number(trueDateSplit[0])) {
        dataObj[i]['日期'] = (Number(dateString[0]) - 1) + '-' + dataObj[i]['日期']
      } else {
        // 如果当前月份大于或等于数据月份
        dataObj[i]['日期'] = dateString[0] + '-' + dataObj[i]['日期']
      }
    }
    const dataItem = dataObj[i].dataDetail
    if (dataItem.length > 0) {
      const dataItemArr = dataItem.split('\n')
      if (dataItemArr.length % 2 === 0) {
        for (const j in dataItemArr) {
          if (j < dataItemArr.length) {
            if ((j % 2) === 0) {
              if (dataItemArr[Number(j) + 1 + ''] === '') {
                dataItemArr[Number(j) + 1 + ''] = 0
              }
              dataObj[i][dataItemArr[j]] = -Number(dataItemArr[Number(j) + 1 + ''])
              // 处理“退款退优惠券”
              if (dataItemArr[j] === '退款退优惠券') {
                dataItemArr[j] = '优惠券'
              }
              dataObj[i][dataItemArr[j]] = -Number(dataItemArr[Number(j) + 1 + ''])
              dataObj[i].dataDetail = null
              dataObj[i].data = null
            }
          }
        }
      }
    }
  }
  ruleFormOperate.list.duizhang = dataObj
  console.error('ruleFormOperate', ruleFormOperate.list.duizhang, dataObj)
}
const tuiguangDataSet = (data) => {
  const dataObj = JSON.parse(data).val
  dataObj.tempId = timeToDate(new Date().getTime() / 1000).substring(2).replace(/-/g, '').replace(/ /g, '').replace(/:/g, '') + (Math.floor(Math.random() * (999999999 - 100000000 + 1)) + 100000000).toString().slice(-9)
  for (const i in dataObj) {
    baseData.callDuizhangRule.map(item => {
      if (item !== '日期') {
        dataObj[i][item] = 0
      }
    })
    // 处理日期 - 针对商品推广
    if (dataObj[i]['日期'].split('/').length > 1) {
      const regex = new RegExp('/', 'g')
      dataObj[i]['日期'] = dataObj[i]['日期'].replace(regex, '-')
    }
  }
  ruleFormOperate.list.tuiguang = dataObj
}
const checkStorage = (type) => {
  const newValue = localStorage.getItem(`main-${type}`)
  let listenSwitch = true
  switch (type) {
    case 'duizhang':
      if (newValue !== mainDuizhang.value) {
        mainDuizhang.value = newValue
        listenSwitch = false
        console.log('成功获取到对账中心的数据', type, newValue)
        duizhangDataSet(newValue)
      }
      break
    case 'tuiguang':
      if (newValue !== mainTuiguang.value) {
        mainTuiguang.value = newValue
        listenSwitch = false
        ruleFormOperate.list.tuiguang = newValue
        console.log('成功获取到推广中心的数据', type, newValue)
        tuiguangDataSet(newValue)
      }
      break
  }
  if (!listenSwitch) {
    clearInterval(mainListen.timeZone)
    mainListen.timeZone = null
    mainListen.tempSecond = 0
    mainListen.btnSwitch = true
  } else {
    mainListen.tempSecond = mainListen.tempSecond + 1
    if (mainListen.tempSecond >= 60) {
      ElMessage.error('监听超时，请重新操作')
      clearInterval(mainListen.timeZone)
      mainListen.timeZone = null
      mainListen.tempSecond = 0
      mainListen.btnSwitch = true
    }
  }
}
const listeningStorage = (type) => {
  setTimeout(() => {
    mainListen.mainType = type
    mainListen.btnSwitch = false
    mainListen.timeZone = setInterval(() => {
      checkStorage(mainListen.mainType)
    }, 1500)
  }, 1000)
}
// 监听localStorage值变化方法 end

const duizhangTableRef = ref(null)
const tuiguangTableRef = ref(null)
const operateTableObj = reactive({
  selectionsDuizhang: [],
  selectedDuizhang: false,
  selectionsTuiguang: [],
  selectedTuiguang: false
})
// 表格添加一行
const addRowHandle = (type) => {
  console.log(type, baseData.callDuizhangRule, ...baseData.callDuizhangRule)
  const temp = {}
  switch (type) {
    case 'duizhang':
      for (const i in baseData.callDuizhangRule) {
        const item = baseData.callDuizhangRule[i]
        temp[item] = ''
      }
      temp.tempId = timeToDate(new Date().getTime() / 1000).substring(2).replace(/-/g, '').replace(/ /g, '').replace(/:/g, '') + (Math.floor(Math.random() * (999999999 - 100000000 + 1)) + 100000000).toString().slice(-9)
      ruleFormOperate.list.duizhang.push(temp)
      nextTick(() => {
        const scrollViewHeight = document.querySelector('.operatefeeBox #cardItem1 .el-scrollbar__view').clientHeight
        document.querySelector('.operatefeeBox #cardItem1 .el-scrollbar__wrap').scrollTop = scrollViewHeight
        const lastIndex = (ruleFormOperate.list.duizhang.length - 1 > 0) ? ruleFormOperate.list.duizhang.length - 1 : 0
        duizhangTableRef.value.setCurrentRow(ruleFormOperate.list.duizhang[lastIndex])
      })
      break
    case 'tuiguang':
      for (const i in baseData.callTuiguangRule) {
        const item = baseData.callTuiguangRule[i]
        temp[item] = ''
      }
      temp.tempId = timeToDate(new Date().getTime() / 1000).substring(2).replace(/-/g, '').replace(/ /g, '').replace(/:/g, '') + (Math.floor(Math.random() * (999999999 - 100000000 + 1)) + 100000000).toString().slice(-9)
      ruleFormOperate.list.tuiguang.push(temp)
      nextTick(() => {
        const scrollViewHeight = document.querySelector('.operatefeeBox #cardItem2 .el-scrollbar__view').clientHeight
        document.querySelector('.operatefeeBox #cardItem2 .el-scrollbar__wrap').scrollTop = scrollViewHeight
        const lastIndex = (ruleFormOperate.list.tuiguang.length - 1 > 0) ? ruleFormOperate.list.tuiguang.length - 1 : 0
        tuiguangTableRef.value.setCurrentRow(ruleFormOperate.list.tuiguang[lastIndex])
      })
      break
  }
}
// 表格选中项事件
const operateTableRefHandle = (type, e) => {
  if (type === 'duizhang') {
    operateTableObj.selectionsDuizhang = []
    operateTableObj.selectedDuizhang = (e.length > 0)
    if (operateTableObj.selectedDuizhang) {
      for (const i in e) {
        operateTableObj.selectionsDuizhang.push(e[i].tempId)
      }
    }
  } else if (type === 'tuiguang') {
    operateTableObj.selectionsTuiguang = []
    operateTableObj.selectedTuiguang = (e.length > 0)
    if (operateTableObj.selectedTuiguang) {
      for (const i in e) {
        operateTableObj.selectionsTuiguang.push(e[i].tempId)
      }
    }
  }
}
// 表格选中项删除事件
const operateTableRefDelete = (type) => {
  if (type === 'duizhang') {
    const duplicateIds = (item) => {
      return (operateTableObj.selectionsDuizhang.indexOf(item.tempId) !== -1)
    }
    const delResList = ruleFormOperate.list.duizhang.filter(item => !duplicateIds(item))
    ruleFormOperate.list.duizhang = delResList
    operateTableObj.selectedDuizhang = false
    ElMessage.success('选中项删除成功')
  } else if (type === 'tuiguang') {
    const duplicateIds = (item) => {
      return (operateTableObj.selectionsTuiguang.indexOf(item.tempId) !== -1)
    }
    const delResList = ruleFormOperate.list.tuiguang.filter(item => !duplicateIds(item))
    ruleFormOperate.list.tuiguang = delResList
    operateTableObj.selectedTuiguang = false
    ElMessage.success('选中项删除成功')
  }
}
</script>

<style lang="scss" scpoed>
@import '~@/styles/variables.scss';
@import '~@/styles/mixin.scss';
@import '~@/styles/report/index.scss';
.operatefeeBox {
  .upload-excel {
    opacity: 0 !important;
    height: 0 !important;
  }
  .card-box {
    .card-bd {
      padding-top: 12px;
    }
    .card-hd-right {
      display: flex;
    }
  }
  .detailFormDialogBox .el-dialog,
  .height-auto-table {
    width: 100%;
  }
  .height-auto-table {
    .el-date-editor {
      width: 100%;
      .el-input__inner {
        padding-left: 11px;
      }
      .el-input__icon {
        display: none;
      }
    }
  }
  .el-tabs {
    min-width: 110px;
  }
  #cardItem0 {
    .el-input__inner {
      width: 320px;
    }
  }
}
</style>
