<template>
  <div class="refreshBox"
    :class="{'isDisabled': baseData.limitSwitch}"
    @click="refreshHandle"
  >
    <div class="refreshBoxIcon"
      :class="{'isTransform': baseData.limitSwitch}">
      <el-icon><RefreshRight /></el-icon>
    </div>
    <div class="refreshBoxText">{{ title }}</div>
  </div>
</template>

<script setup>
// eslint-disable-next-line
import { reactive, defineProps, defineEmits } from 'vue'

defineProps({
  title: {
    type: String,
    default: '刷新'
  }
})
const baseData = reactive({
  limitSwitch: false,
  timeZone: ''
})

const emit = defineEmits(['refreshCall'])

const refreshHandle = () => {
  if (baseData.limitSwitch) return
  baseData.limitSwitch = true
  baseData.timeZone = setTimeout(() => {
    baseData.limitSwitch = false
  }, 3000)
  emit('refreshCall', baseData.limitSwitch)
}

</script>

<style lang="scss" scoped>
.refreshBox {
  display: flex;
  align-items: center;
  transition: all 0.3s;
  color: #6F61E6;
  cursor: pointer;
  &Icon {
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(0deg);
  }
  .isTransform {
    animation: transMove 0.5s 1;
    -webkit-animation: transMove 0.5s 1; /*Safari and Chrome*/
  }
  &Text {
    margin-left: 4px;
  }
}
.refresh-between {
  margin: 0 4px;
}
@keyframes transMove {
from {
  transform: rotate(0deg);
}
to {
  transform: rotate(360deg);
}
}
@-webkit-keyframes transMove {
from {
  transform: rotate(0deg);
}
to {
  transform: rotate(360deg);
}
}
</style>
