<template>
  <div class="navbar">
    <!-- hamburger 用于控制左侧栏的伸缩 -->
    <!-- <hamburger class="hamburger-container" /> -->
    <!-- <breadcrumb id="guide-breadcrumb" class="breadcrumb-container" /> -->

    <!-- tags -->
    <tags-view id="guide-tags"></tags-view>

    <div class="right-menu">
      <!-- <guide class="right-menu-item hover-effect" /> -->
      <!-- <header-search class="right-menu-item hover-effect"></header-search> -->
      <svg-icon class="right-menu-item hover-effect supplychain-icon" icon="supplychain-bell" @click="noticeHandle" />
      <!-- <screenfull class="right-menu-item hover-effect" /> -->
      <!-- <theme-picker class="right-menu-item hover-effect"></theme-picker> -->
      <!-- <lang-select class="right-menu-item hover-effect" /> -->
      <!-- 头像 -->
      <el-dropdown
        popper-class="avatar-popper"
        class="avatar-container"
        trigger="hover"
      >
        <div class="avatar-wrapper">
          <el-avatar
            shape="circle"
            :size="40"
            :src="$store.getters.userInfo.avatar"
          ></el-avatar>
          <i class="el-icon-s-tools"></i>
        </div>
        <template #dropdown>
          <div class="dropdown-msg">
            <div class="dropdown-msg-bg"></div>
            <div class="dropdown-msg-head">
              <el-avatar
                shape="circle"
                :size="40"
                :src="$store.getters.userInfo.avatar"
              ></el-avatar>
              <div class="dropdown-msg-head-r">
                <span class="user-msg-name" :title="$store.getters.userInfo.username">{{ $store.getters.userInfo.username }}</span>
                <span class="user-msg-lv">账户ID：{{ $store.getters.userInfo.id }}</span>
              </div>
            </div>
            <el-dropdown-menu class="user-dropdown">
              <router-link to="/">
                <el-dropdown-item> {{ $t('msg.navBar.home') }} </el-dropdown-item>
              </router-link>
              <el-dropdown-item divided @click="logout()">
                {{ $t('msg.navBar.logout') }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </div>
        </template>
      </el-dropdown>
    </div>

    <noticeCenterDialog
      :isShow="baseData.noticeShow"
      @close="baseData.noticeShow = false"
      @subCall="checkProtocolHandle"
    ></noticeCenterDialog>
  </div>
</template>

<script setup>
import { reactive } from 'vue'
import { useStore } from 'vuex'
// import Hamburger from '@/components/Hamburger/index'
// import Breadcrumb from '@/components/Breadcrumb/index'
// import LangSelect from '@/components/LangSelect'
// import ThemePicker from '@/components/ThemeSelect/index'
// import Screenfull from '@/components/Screenfull'
import TagsView from '@/components/TagsView'
// import HeaderSearch from '@/components/HeaderSearch'
// import Guide from '@/components/Guide'
import noticeCenterDialog from '@/components/GlobalDialog/noticeCenterDialog.vue'

const store = useStore()

const baseData = reactive({
  noticeShow: false
})

const logout = () => {
  store.dispatch('user/logout')
}

const noticeHandle = () => {
  console.error('点击了公告/通知')
  baseData.noticeShow = true
}
</script>

<style lang="scss" scoped>
@import '~@/styles/variables.scss';
.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0px 1px 3px 0px rgba(111, 97, 230, 0.1);
  display: flex;
  align-items: center;

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    // hover 动画
    transition: background 0.5s;

    &:hover {
      background: #6F61E61a;
    }
  }
  .breadcrumb-container {
    float: left;
  }
  .right-menu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 180px;
    // padding-right: 16px;

    :deep .avatar-container {
      cursor: pointer;
      .avatar-wrapper {
        position: relative;
        display: flex;
        align-items: center;
        .user-msg {
          &-name,
          &-lv {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          &-lv {
            font-size: 12px;
            margin-top: 3px;
          }
        }
        .el-avatar {
          --el-avatar-background-color: none;
          margin-right: 12px;
          margin-left: 6px;
          width: 36px;
          height: 36px;
        }
      }
    }
    :deep .right-menu-item {
      display: inline-block;
      padding: 0px 18px 0 0;
      font-size: 24px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        &:hover {
          color: $menuHover;
        }
      }
    }
  }
}
.avatar-popper {
  width: 240px;
  height: 180px;
  border-radius: 8px;
  overflow: hidden;
  .dropdown-msg {
    position: relative;
    width: 100%;
    background-image: $menuBg;
    padding: 0 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    &-head {
      margin: 12px 0;
      display: flex;
      align-items: center;
      &-r {
        display: flex;
        flex-direction: column;
        margin-left: 6px;
        height: 100%;
        justify-content: center;
        font-size: 14px;
        width: 140px;
        color: $mainBlack;
      }
    }
    .el-avatar {
      width: 64px;
      height: 64px;
    }
  }
  .user-dropdown {
    background: unset;
    width: 100%;
  }
}
.supplychain-icon {
  font-size: 30px !important;
}
</style>
