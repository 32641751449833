<template>
  <div class="storeBox">
    <div class="card-box">
      <div class="card-hd">
        <div class="card-hd-left">
          后勤模板列表
        </div>
        <div class="card-hd-right">
          <Refresh @refreshCall="getAllTemplateList"></Refresh>
        </div>
      </div>
    </div>

    <div class="handleLineBox">
      <!-- select -->
      <div class="baseSelect">
        <!-- 模糊搜索 -->
        <SearchBox
          :searchVal="baseData.searchVal"
          placeholder="请输入后勤模板名称"
          @searchCall="searchHandle"
        ></SearchBox>
        <!-- 下拉选择 -->
        <SelectDown v-if="false"
          :selectVal="baseData.selectVal"
          :selectData="baseData.selectData"
          @selectDownCall="selectDownHandle"
        ></SelectDown>
        <!-- 弹窗 -->
        <el-button type="primary" icon="CirclePlus" @click="SimpleDialogHandle('template')">新增后勤模板</el-button>
        <!-- 弹窗 -->
        <div class="detailFormDialogBox">
          <el-dialog
              v-model="baseData.SimpleDialogShow"
              width="1140px"
              align-center
              :close-on-click-modal="false"
              @close="resetForm(ruleFormRef)"
          >
            <div class="card-box">
              <div class="card-hd">
                <div class="card-hd-left">
                  {{ ruleForm?.tamplateId ? '编辑' : '新增'}}后勤模板
                </div>
                <div class="card-hd-right">
                </div>
              </div>
              <div class="card-bd">
                <div class="goodsForm">
                  <el-tabs tab-position="left"
                    v-model="tabsHd.currentIndex"
                    @tab-click="tabsListHandle($event, tabsHd, baseData, goodsFormScoll)">
                    <el-tab-pane
                      v-for="item in tabsHd.list"
                      :key="item.name"
                      :label="item.title"
                      :name="item.name">
                      <template #label>
                        {{ item.title }}
                      </template>
                    </el-tab-pane>
                  </el-tabs>
                  <div class="goodsFormBox">
                    <el-scrollbar
                      @scroll="doScroll($event, tabsHd, baseData)"
                      ref="goodsFormScoll"
                      :always="true">
                      <el-form
                        ref="ruleFormRef"
                        :model="ruleForm"
                        status-icon
                        :rules="rules"
                      >
                        <div class="card-box" id="cardItem0">
                          <div class="card-hd">
                            <div class="card-hd-left">
                              基础信息
                            </div>
                            <div class="card-hd-right">
                            </div>
                          </div>
                          <div class="card-bd">
                            <div class="card-bd-item is-alone">
                              <el-form-item label="后勤模板名称" prop="templateName">
                                <el-input v-model="ruleForm.templateName"
                                  :min="0"
                                  maxlength="30"
                                  placeholder="请填写后勤模板名称"
                                  show-word-limit
                                  clearable
                                  style="width: 320px"
                                >
                                </el-input>
                              </el-form-item>
                            </div>
                          </div>
                        </div>
                        <div class="card-box" id="cardItem1">
                          <div class="card-hd">
                            <div class="card-hd-left">
                              有效订单设置
                            </div>
                            <div class="card-hd-right">
                            </div>
                          </div>
                          <div class="card-bd">
                            <div class="card-bd-item is-alone">
                              <el-form-item label="有效订单不包括：" prop="trueOrderSelectArr">
                                <el-checkbox-group
                                  v-model="ruleForm.trueOrderSelectArr"
                                >
                                  <el-checkbox
                                    v-for="item in ruleForm.trueOrderTypeSelect"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                  />
                                </el-checkbox-group>
                              </el-form-item>
                            </div>
                            <div class="card-bd-item is-alone greylight2">
                              （ 有效订单 = 【全部订单】
                              <span v-for="item in ruleForm.trueOrderSelectArr" :key="item">
                                - {{ item }}
                              </span>
                              ）
                            </div>
                          </div>
                        </div>
                        <div class="card-box" id="cardItem2">
                          <div class="card-hd">
                            <div class="card-hd-left">
                              人工/快递支出设置
                            </div>
                            <div class="card-hd-right">
                            </div>
                          </div>
                          <div class="card-bd">
                            <div class="card-bd-item">
                              <el-form-item label="预估快递费/单" prop="globalExpressdeliveryFee">
                                <el-input v-model="ruleForm.globalExpressdeliveryFee"
                                  type="number"
                                  :min="0"
                                  :max="99999"
                                  :precision="2"
                                  step="0.1"
                                  placeholder="请设置费用"
                                  show-word-limit
                                  @input="inputNumberCheck($event, 'globalExpressdeliveryFee')"
                                  @change="changeNumberCheck($event, 'globalExpressdeliveryFee')"
                                >
                                  <template #append>元</template>
                                </el-input>
                              </el-form-item>
                              <el-popover
                                placement="right"
                                :width="200"
                                trigger="hover"
                                content="每单预估快递费(参考：0.1)"
                              >
                                <template #reference>
                                  <el-icon class="card-bd-info-icon"><InfoFilled /></el-icon>
                                </template>
                              </el-popover>
                            </div>
                            <div class="card-bd-item">
                              <el-form-item label="预估人工费/单" prop="globalLaborFee">
                                <el-input v-model="ruleForm.globalLaborFee"
                                  type="number"
                                  :min="0"
                                  :max="99999"
                                  :precision="2"
                                  step="0.1"
                                  placeholder="请设置费用"
                                  show-word-limit
                                  @input="inputNumberCheck($event, 'globalLaborFee')"
                                  @change="changeNumberCheck($event, 'globalLaborFee')"
                                >
                                  <template #append>元</template>
                                </el-input>
                              </el-form-item>
                              <el-popover
                                placement="right"
                                :width="200"
                                trigger="hover"
                                content="每单预估人工费(参考：0.1)"
                              >
                                <template #reference>
                                  <el-icon class="card-bd-info-icon"><InfoFilled /></el-icon>
                                </template>
                              </el-popover>
                            </div>
                          </div>
                        </div>
                      </el-form>
                    </el-scrollbar>
                  </div>
                </div>
              </div>
            </div>
            <template #footer>
              <span class="dialog-footer" v-if="ruleForm?.tamplateId">
                <el-button type="primary" @click="submitForm(ruleFormRef, 'edit')">保存编辑</el-button>
              </span>
              <span class="dialog-footer" v-else>
                <el-button type="primary" @click="submitForm(ruleFormRef)">保存</el-button>
                <el-button @click="resetForm(ruleFormRef, '重置')">重置</el-button>
              </span>
            </template>
          </el-dialog>
        </div>
      </div>
    </div>

    <!-- table -->
    <div class="baseTable">
      <!-- <el-scrollbar> -->
      <el-table
        v-loading="baseData.tableLoading"
        :data="baseData.tableListData"
        style="width: 100%;">
        <el-table-column
          v-for="(item, index) in baseData.tableHdData"
          :key="index"
          :prop="item.prop"
          :label="item.label"
          :min-width="item?.minWidth"
          :fixed="item.prop == 'handle' ? 'right' : item.prop == 'name' ? 'left' : false"
          align="center"
        >
          <template #default="scope">
            <div v-if="item.prop == 'handle'">
              <el-button size="small" type="primary"
                @click="editTemplateHandle(scope.row.id, scope.row.name)">
                编辑
              </el-button>
              <el-button size="small" type="danger"
                @click="delTemplateHandle(scope.row.id)">
                删除
              </el-button>
            </div>
            <span v-else>
              {{ scope.row[item.prop] }}
            </span>
          </template>
        </el-table-column>
        <!-- table empty -->
        <template #empty>
          <div class="baseTableEmpty">
            <el-empty description="暂无后勤模板">
              <el-button type="primary" icon="CirclePlus" @click="SimpleDialogHandle('template')">新增后勤模板</el-button>
            </el-empty>
          </div>
        </template>
      </el-table>
      <!-- </el-scrollbar> -->
    </div>

    <!-- pagination 分页 -->
    <div class="basePagination">
      <el-config-provider :locale="zhCn">
        <el-pagination
          v-model:current-page="baseData.currentPage"
          v-model:page-size="baseData.pageSize"
          :page-sizes="baseData.pageSizes"
          layout="total, sizes, prev, pager, next, jumper"
          :total="baseData.pageTotal"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </el-config-provider>
    </div>

  </div>
</template>

<script setup>
import zhCn from 'element-plus/lib/locale/lang/zh-cn'
// eslint-disable-next-line
import SelectDown from '@/components/SelectDown/index.vue'
import SearchBox from '@/components/SearchBox/index.vue'
import Refresh from '@/components/Refresh/index.vue'
import { onMounted, reactive, watch, ref } from 'vue'
import { ElMessageBox, ElMessage } from 'element-plus'
import {
  logisticsTemplateList,
  logisticsTemplatePageList,
  logisticsTemplateAdd,
  logisticsTemplateDel,
  logisticsTemplateDetail
} from '@/api/report.js'
import { timeToDate } from '@/utils/unitTransform.js'
import { tabsListHandle, doScroll, getCardItemSite } from '@/utils/general.js'
import { useRoute } from 'vue-router'

const route = useRoute()

const baseData = reactive({
  selectTimes: '',
  selectVal: 'Option2',
  selectData: [
    {
      value: 'Option1',
      label: 'Option1'
    },
    {
      value: 'Option2',
      label: 'Option2'
    }
  ],
  tableLoading: false,
  tableHdData: [
    { label: '模板名称', prop: 'name', minWidth: '200' },
    { label: '创建时间', prop: 'created_at', minWidth: '180' },
    { label: '操作', prop: 'handle', minWidth: '250' }
  ],
  tableListData: [],
  currentPage: 1,
  pageSize: 100,
  pageTotal: 0,
  pageSizes: [100, 200, 300, 400],
  SimpleDialogShow: false,
  searchVal: ''
})
// 基础组件 事务 start
const goodsFormScoll = ref()
// tabs信息
const tabsHd = reactive({
  currentIndex: 0,
  list: [
    { title: '基础信息', name: 0 },
    { title: '有效订单设置', name: 1 },
    { title: '人工/快递支出', name: 2 }
  ]
})

// input @input 键入数字校验
const inputNumberCheck = (e, ruleFormKey) => {
  if (Number(e) > 9999) {
    ruleForm[ruleFormKey] = 9999
  }
  if (Number(e) < 0) {
    ruleForm[ruleFormKey] = 0
  }
}
// input @change 键入数字校验
const changeNumberCheck = (e, ruleFormKey) => {
  ruleForm[ruleFormKey] = Number(e)
}
// 搜索事件
const searchHandle = (type, searchBox) => {
  baseData.searchVal = type ? searchBox.searchVal : ''
  getTemplatePageList()
}

// tabs currentIndex监听
watch(() => tabsHd.currentIndex, (newValue) => {
  console.log('watch 已触发', newValue)
})
watch(() => baseData.SimpleDialogShow, (newValue) => {
  newValue && getCardItemSite(tabsHd)
})

// 后勤模板表单 action
// 表单 信息
const ruleForm = reactive({
  orderStatus: ['待付款', '已取消', '待发货', '未发货，退款成功', '已发货，待收货', '已发货，退款成功', '已收货', '已收货，退款成功'],
  templateName: '', // 模板名称
  trueOrderSelectArr: ['待付款', '已取消', '待发货', '未发货，退款成功', '已发货，退款成功', '已收货，退款成功'], // 有效订单=减去对应类型(减法)
  trueOrderTypeSelect: [
    {
      value: '1',
      label: '待付款'
    },
    {
      value: '2',
      label: '已取消'
    },
    {
      value: '3',
      label: '待发货'
    },
    {
      value: '4',
      label: '未发货，退款成功'
    },
    {
      value: '5',
      label: '已发货，待收货'
    },
    {
      value: '6',
      label: '已发货，退款成功'
    },
    {
      value: '7',
      label: '已收货'
    },
    {
      value: '8',
      label: '已收货，退款成功'
    }
  ],
  globalLaborFee: '', // 全局人工费
  globalExpressdeliveryFee: '' // 全局快递费
})
// 表单 验证相关
const ruleFormRef = ref()
// 表单 模板名校验规则
const checkTemplateName = (rule, value, callback) => {
  if (!value) {
    return callback(new Error('请输入模板名称'))
  } else {
    callback()
  }
}
// 表单 费用校验规则
const checkFee = (rule, value, callback) => {
  if (!value) {
    return callback(new Error('请设置费用'))
  } else {
    callback()
  }
}
// 表单 校验规则
const rules = reactive({
  templateName: [{ required: true, validator: checkTemplateName, trigger: 'blur' }],
  globalLaborFee: [{ required: true, validator: checkFee, trigger: 'blur' }],
  globalExpressdeliveryFee: [{ required: true, validator: checkFee, trigger: 'blur' }]
})
// 表单 验证后提交表单
const submitForm = (formEl, isEdit) => {
  if (!formEl) return
  formEl.validate((valid) => {
    if (valid) {
      console.log('提交!', ruleForm)
      // 收集多选id
      const lastSelectIdArr = []
      for (const i in ruleForm.trueOrderSelectArr) {
        for (const j in ruleForm.trueOrderTypeSelect) {
          const defaultArr = ruleForm.trueOrderTypeSelect[j]
          const selectItem = ruleForm.trueOrderSelectArr[i]
          if (defaultArr.label === selectItem) {
            lastSelectIdArr.push(defaultArr.value)
            break
          }
        }
      }
      if (!isEdit) {
        addTemplateHandle(ruleForm, lastSelectIdArr)
      } else {
        ElMessage.error('后勤模板编辑功能制作中 Comedy')
      }
    } else {
      console.log('不能提交')
      return false
    }
  })
}
// 表单 重置表单
const resetForm = (formEl, typeName) => {
  if (!formEl) return
  if (typeName) {
    ElMessageBox.confirm(
      `${typeName}后所有未保存的内容均会丢失，是否继续？`,
      {
        confirmButtonText: '继续',
        cancelButtonText: '取消',
        type: 'warning'
      }
    ).then(() => {
      formEl.resetFields()
    }).catch(() => {
    })
  } else {
    formEl.resetFields()
    clearEditObject()
  }
}

const clearEditObject = () => {
  ruleForm.templateName = null
  ruleForm.tamplateId = null
}

// 分页相关
const handleSizeChange = (val) => {
  console.log(`${val} items per page`)
}
const handleCurrentChange = (val) => {
  console.log(`current page: ${val}`)
}
// 下拉选择相关
const selectDownHandle = (val) => {
  baseData.selectVal = val
}
// simpleDialog 弹窗唤醒事件
const SimpleDialogHandle = (type) => {
  baseData.SimpleDialogShow = true
}

// 获取模板列表
const getTemplateList = async () => {
  const res = await logisticsTemplateList({
    template_name: baseData.searchVal
  })
  console.log('getTemplateList', res)
}
// 获取模板分页列表
const getTemplatePageList = async () => {
  const resData = await logisticsTemplatePageList({
    template_name: baseData.searchVal,
    page: baseData.currentPage,
    page_size: baseData.pageSize
  })
  for (const i in resData.data) {
    const dataItem = resData.data[i]
    if (dataItem?.created_at) {
      dataItem.created_at = timeToDate(dataItem?.created_at)
    }
  }
  baseData.tableListData = resData.data
  baseData.pageTotal = resData.total
  console.log('getTemplatePageList', resData)
}
// 新增模板接口
const addTemplateHandle = async (ruleForm, lastSelectIdArr) => {
  await logisticsTemplateAdd({
    template_name: ruleForm.templateName,
    invalid_order_status: lastSelectIdArr,
    expected_express_fee: ruleForm.globalExpressdeliveryFee,
    expected_labor_cost: ruleForm.globalLaborFee
  })
  getTemplatePageList()
  baseData.SimpleDialogShow = false
}
// 编辑模板接口
const editTemplateHandle = async (id, templateName) => {
  console.log('editTemplateHandle 编辑模板接口 Comedy', ruleForm)
  const resData = await logisticsTemplateDetail({
    template_id: id
  })
  const {
    expected_express_fee: globalExpressdeliveryFee,
    expected_labor_cost: globalLaborFee,
    invalid_order_status: lastSelectIdArr,
    template_id: tamplateId
  } = resData
  // 订单状态赋值
  const editStatusArr = JSON.parse(lastSelectIdArr)
  const editStatusChinaArr = []
  for (const i in ruleForm.trueOrderTypeSelect) {
    const definedItem = ruleForm.trueOrderTypeSelect[i]
    if (editStatusArr.indexOf(definedItem.value) !== -1) {
      editStatusChinaArr.push(definedItem.label)
    }
  }
  ruleForm.trueOrderSelectArr = editStatusChinaArr
  // 模板字段赋值
  Object.assign(ruleForm, {
    globalExpressdeliveryFee,
    globalLaborFee,
    templateName,
    tamplateId
  })
  baseData.SimpleDialogShow = true
}
// 删除模板事件 — 询问
const delTemplateHandle = (id) => {
  ElMessageBox.confirm(
    '删除后该模板将不可再次使用，是否继续？',
    {
      confirmButtonText: '删除',
      cancelButtonText: '取消',
      type: 'warning'
    }
  ).then(() => {
    delApiHandle(id)
  }).catch(() => {
  })
}
// 删除模板事件 — 接口
const delApiHandle = async (id) => {
  await logisticsTemplateDel({
    ids: [id]
  })
  getTemplatePageList()
}

const getAllTemplateList = () => {
  getTemplateList()
  getTemplatePageList()
}

onMounted(() => {
  getAllTemplateList()
})

watch(() => route.params, (newParams) => {
  if (newParams.event === 'addTemplate') SimpleDialogHandle('template')
}, {
  immediate: true
})
</script>

<style lang="scss" >
@import '~@/styles/variables.scss';
@import '~@/styles/mixin.scss';
@import '~@/styles/report/index.scss';
</style>
