<template>
  <div class="storeBox skucostBox">
    <div class="card-box">
      <div class="card-hd">
        <div class="card-hd-left">
          SKU成本表列表
        </div>
        <div class="card-hd-right">
          <Refresh @refreshCall="getAllSkuCostList"></Refresh>
        </div>
      </div>
    </div>

    <div class="handleLineBox">
      <!-- select -->
      <div class="baseSelect">
        <!-- 模糊搜索 -->
        <SearchBox
          :searchVal="baseData.searchVal"
          placeholder="请输入Sku成本表名称"
          @searchCall="searchHandle"
        ></SearchBox>
        <!-- 下拉选择 -->
        <SelectDown v-if="false"
          :selectVal="baseData.selectVal"
          :selectData="baseData.selectData"
          @selectDownCall="selectDownHandle"
        ></SelectDown>
        <el-button type="primary" icon="CirclePlus" @click="SimpleDialogHandle('sku')">新增SKU成本表</el-button>
        <!-- 弹窗 Sku层 -->
        <div class="detailFormDialogBox">
          <el-dialog
              v-model="baseData.SimpleDialogShow"
              width="1140px"
              align-center
              :close-on-click-modal="false"
              @close="resetForm(ruleFormSkuCostRef)"
          >
            <div class="card-box">
              <div class="card-hd">
                <div class="card-hd-left">
                  {{ ruleFormSkuCost?.templateId ? '编辑' : '新增'}}SKU成本表
                </div>
                <div class="card-hd-right">
                </div>
              </div>
              <div class="card-bd">
                <div class="goodsForm">
                  <el-tabs tab-position="left"
                    v-model="tabsHd.currentIndex"
                    @tab-click="tabsListHandle($event, tabsHd, baseData, goodsFormScoll)">
                    <el-tab-pane
                      v-for="item in tabsHd.list"
                      :key="item.name"
                      :label="item.title"
                      :name="item.name">
                      <template #label>
                        {{ item.title }}
                      </template>
                    </el-tab-pane>
                  </el-tabs>
                  <div class="goodsFormBox">
                    <el-scrollbar
                      ref="goodsFormScoll"
                      :always="true">
                      <el-form
                        ref="ruleFormSkuCostRef"
                        :model="ruleFormSkuCost"
                        status-icon
                      >
                        <div class="card-box" id="cardItem0">
                          <div class="card-hd">
                            <div class="card-hd-left">
                              基础信息
                            </div>
                            <div class="card-hd-right">
                            </div>
                          </div>
                          <div class="card-bd">
                            <div class="card-bd-item is-alone">
                              <el-form-item
                                label="SKU成本表名称"
                                prop="templateName"
                                :rules="rulesSkuCost.templateName"
                              >
                                <el-input v-model="ruleFormSkuCost.templateName"
                                  :min="0"
                                  maxlength="30"
                                  placeholder="SKU成本表名称"
                                  show-word-limit
                                  clearable
                                  style="width: 320px"
                                  :disabled="ruleFormSkuCost?.templateId"
                                >
                                </el-input>
                                <div class="greylight2" v-if="ruleFormSkuCost.list.length > 0">
                                  （共{{ ruleFormSkuCost.list.length }}条SKU）
                                </div>
                              </el-form-item>
                            </div>
                            <div class="card-bd-item is-alone greylight2">
                              {{ ruleFormSkuCost.templateId ? '编辑状态下将为表名添加时间后缀：xxx*(2401010930)' : '' }}
                            </div>
                          </div>
                        </div>
                        <div class="card-box" id="cardItem1">
                          <div class="card-hd">
                            <div class="card-hd-left">
                              SKU成本表
                              <a href="https://donkey.agreatthinking.com/excel/SKU%E6%88%90%E6%9C%AC%E8%A1%A8-%E6%A8%A1%E6%9D%BF.xlsx"
                                target="_blank" class="a-default"
                              >
                                点击下载SKU成本表模板
                              </a>
                            </div>
                            <div class="card-hd-right card-right-btns">
                              <el-button
                                type="danger"
                                @click="skucostTableRefDelete"
                                :disabled="!skucostTableObj.selected"
                              >删除选中</el-button>
                              <el-button
                                type="primary"
                                icon="Upload"
                                @click="comHandleHandleUpload('sku')"
                              >上传SKU成本表</el-button>
                              <el-button
                                type="primary"
                                icon="plus"
                                @click="addRowHandle"
                              >添加一行</el-button>
                            </div>
                          </div>
                          <div class="card-bd">
                            <div class="height-auto-table">
                              <el-table
                                :data="ruleFormSkuCost.list"
                                stripe
                                style="width: 940px"
                                ref="skucostTableRef"
                                highlight-current-row
                                autocomplete="off"
                                @selection-change="skucostTableRefHandle"
                              >
                                <el-table-column type="selection" width="55" />
                                <el-table-column v-for="(item, index) in baseData.skuCostTableHd" :key="index"
                                  :prop="item.label" :label="item.label">
                                  <template #default="{ row, $index }">
                                    <el-form-item
                                      :prop="`list.${$index}.${item.label}`"
                                      :rules="rulesSkuCost.list[item.label]"
                                    >
                                      <el-input
                                        :type="item.label === 'SKU价格' ? 'Number' : ''"
                                        v-model="row[item.label]"
                                        :min="0"
                                        :max="100"
                                        :placeholder="`请输入${item.label}`"
                                        clearable
                                      ></el-input>
                                    </el-form-item>
                                  </template>
                                </el-table-column>
                                <template #empty>
                                  <el-empty description="SKU成本表暂无内容"></el-empty>
                                </template>
                              </el-table>
                            </div>
                          </div>
                        </div>
                      </el-form>
                    </el-scrollbar>
                  </div>
                </div>
              </div>
            </div>
            <template #footer>
              <span class="dialog-footer" v-if="ruleFormSkuCost?.templateId">
                <el-button type="primary" @click="submitFormSkuCost(ruleFormSkuCostRef, 'edit')">
                  保存编辑
                </el-button>
              </span>
              <span class="dialog-footer" v-else>
                <el-button type="primary" @click="submitFormSkuCost(ruleFormSkuCostRef)">
                  保存
                </el-button>
              </span>
            </template>
          </el-dialog>
        </div>
      </div>
    </div>

    <!-- table -->
    <div class="baseTable">
      <!-- <el-scrollbar> -->
      <el-table
        v-loading="baseData.tableLoading"
        :data="baseData.tableListData"
        style="width: 100%;">
        <el-table-column
          v-for="(item, index) in baseData.tableHdData"
          :key="index"
          :prop="item.prop"
          :label="item.label"
          :min-width="item?.minWidth"
          :fixed="item.prop == 'handle' ? 'right' : item.prop == 'name' ? 'left' : false"
          align="center"
        >
          <template #default="scope">
            <el-tag
              v-if="item.prop == 'platform'"
              type="danger"
              disable-transitions
            >
              {{ scope.row.platform }}
            </el-tag>
            <div v-else-if="item.prop == 'handle'">
              <el-button size="small" type="primary"
                @click="editSkuCostHandle(scope.row.id, scope.row.name)">
                编辑
              </el-button>
              <el-button size="small" type="danger" @click="delSkuCostHandle(scope.row.id)">
                删除
              </el-button>
            </div>
            <div v-else-if="item.prop == 'address'">
              {{ scope?.row[item.prop] ? scope.row[item.prop] : '未设置' }}
            </div>
            <span v-else>
              {{ scope.row[item.prop] }}
            </span>
          </template>
        </el-table-column>
        <!-- table empty -->
        <template #empty>
          <div class="baseTableEmpty">
            <el-empty description="暂无SKU成本表">
              <el-button type="primary" icon="CirclePlus" @click="SimpleDialogHandle('sku')">新增SKU成本表</el-button>
            </el-empty>
          </div>
        </template>
      </el-table>
      <!-- </el-scrollbar> -->
    </div>

    <!-- pagination 分页 -->
    <div class="basePagination">
      <el-config-provider :locale="zhCn">
        <el-pagination
          v-model:current-page="baseData.currentPage"
          v-model:page-size="baseData.pageSize"
          :page-sizes="baseData.pageSizes"
          layout="total, sizes, prev, pager, next, jumper"
          :total="baseData.pageTotal"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </el-config-provider>
    </div>

    <UploadExcel
      :onSuccess="excelCall"
      ref="UploadExcelCom"
      v-show="exlcelData?.header.length === 0"
    ></UploadExcel>

  </div>
</template>

<script setup>
import zhCn from 'element-plus/lib/locale/lang/zh-cn'
// eslint-disable-next-line
import UploadExcel from '@/components/UploadExcel/index.vue'
import SelectDown from '@/components/SelectDown/index.vue'
import SearchBox from '@/components/SearchBox/index.vue'
import Refresh from '@/components/Refresh/index.vue'
import { onMounted, reactive, watch, ref, nextTick } from 'vue'
import {
  skuCostList,
  skuCostPageList,
  skuCostAdd,
  skuCostDel,
  skuCostDetail
} from '@/api/report.js'
import { ElMessage, ElMessageBox } from 'element-plus'
import { timeToDate, jsonToNewName } from '@/utils/unitTransform.js'
import { tabsListHandle, getCardItemSite } from '@/utils/general.js'
import { useRoute } from 'vue-router'

const route = useRoute()

const UploadExcelCom = ref(null)
const exlcelData = reactive({
  header: [],
  results: []
})
const baseData = reactive({
  selectTimes: '',
  selectVal: 'Option2',
  selectData: [
    {
      value: 'Option1',
      label: 'Option1'
    },
    {
      value: 'Option2',
      label: 'Option2'
    }
  ],
  tableLoading: false,
  tableHdData: [
    { label: 'SKU成本表名称', prop: 'name', minWidth: '200' },
    { label: '创建时间', prop: 'created_at', minWidth: '180' },
    { label: '操作', prop: 'handle', minWidth: '250' }
  ],
  tableListData: [],
  currentPage: 1,
  pageSize: 100,
  pageTotal: 0,
  pageSizes: [100, 200, 300, 400],
  SimpleDialogShow: false,
  searchVal: '',
  skuCostTableHd: [
    { label: 'SKU名称' },
    { label: 'SKU价格', width: 120 }
  ]
})
// 基础组件 事务 start
const goodsFormScoll = ref()
// tabs信息
// tabs sku信息
const tabsHd = reactive({
  currentIndex: 0,
  list: [
    { title: '基础信息', name: 0 },
    { title: 'sku成本表', name: 1 }
  ]
})

// 搜索事件
const searchHandle = (type, searchBox) => {
  baseData.searchVal = type ? searchBox.searchVal : ''
  getSkuCostPageList()
}

// tabs currentIndex监听
watch(() => tabsHd.currentIndex, (newValue) => {
  console.log('watch 已触发', newValue)
})
watch(() => baseData.SimpleDialogShow, (newValue) => {
  newValue && getCardItemSite(tabsHd)
})

// Sku成本表表单 action
// 表单 信息
const ruleFormSkuCost = reactive({
  templateName: '', // 模板名称
  list: []
})
// 表单 验证相关
const ruleFormSkuCostRef = ref()
// 表单 模板名校验规则
const checkSkuCostName = (rule, value, callback) => {
  if (!value) {
    return callback(new Error('请输入模板名称'))
  } else {
    callback()
  }
}
const checkValueNull = (rule, value, callback) => {
  if (!value) {
    return callback(new Error('请填写输入项'))
  } else {
    callback()
  }
}
// 表单 校验规则
const rulesSkuCost = reactive({
  templateName: [{ required: true, validator: checkSkuCostName, trigger: 'blur' }],
  list: {
    // eslint-disable-next-line
    'SKU名称': [{ required: true, validator: checkValueNull, trigger: 'submit' }],
    // eslint-disable-next-line
    'SKU价格': [{ required: true, validator: checkValueNull, trigger: 'submit' }]
  }
})
// 表单 验证后提交表单
const submitFormSkuCost = (formEl, isEdit) => {
  if (!formEl) return
  formEl.validate((valid) => {
    if (valid) {
      console.log('提交!', ruleFormSkuCost)
      addSkuCostHandle(ruleFormSkuCost, isEdit)
    } else {
      console.log('不能提交')
      // 锚点到sku列表里面的某一行错误项
      if (document.querySelectorAll('.el-form-item.is-error')) {
        const isErrorLine = document.querySelectorAll('.el-form-item.is-error')[0]
        if (isErrorLine.closest('.el-table__row')) {
          const getOffsetTop = isErrorLine.closest('.el-table__row').offsetTop
          document.querySelector('.skucostBox .goodsFormBox .height-auto-table .el-scrollbar__wrap').scrollTop = getOffsetTop
        }
      }
      ElMessage.error('请填写输入项')
      return false
    }
  })
}
// 表单 重置表单
const resetForm = (formEl, typeName) => {
  if (!formEl) return
  if (typeName) {
    ElMessageBox.confirm(
      `${typeName}后所有未保存的内容均会丢失，是否继续？`,
      {
        confirmButtonText: '继续',
        cancelButtonText: '取消',
        type: 'warning'
      }
    ).then(() => {
      formEl.resetFields()
    }).catch(() => {
    })
  } else {
    formEl.resetFields()
    clearEditObject()
  }
}

const clearEditObject = () => {
  ruleFormSkuCost.templateName = null
  ruleFormSkuCost.templateId = null
  ruleFormSkuCost.list = []
}
// Sku成本表表单 end
// 基础组件 事务 end

// 分页相关
const handleSizeChange = (val) => {
  console.log(`${val} items per page`)
}
const handleCurrentChange = (val) => {
  console.log(`current page: ${val}`)
}
// 下拉选择相关
const selectDownHandle = (val) => {
  baseData.selectVal = val
}
// simpleDialog 弹窗唤醒事件
const SimpleDialogHandle = (type) => {
  baseData.SimpleDialogShow = true
}

// 获取SKU成本列表
const getSkuCostList = async () => {
  const res = await skuCostList({
    template_name: baseData.searchVal
  })
  console.log('getSkuCostList', res)
}
// 获取SKU成本分页列表
const getSkuCostPageList = async () => {
  const resData = await skuCostPageList({
    template_name: baseData.searchVal,
    page: baseData.currentPage,
    page_size: baseData.pageSize
  })
  for (const i in resData.data) {
    const dataItem = resData.data[i]
    if (dataItem?.created_at) {
      dataItem.created_at = timeToDate(dataItem?.created_at)
    }
  }
  baseData.tableListData = resData.data
  baseData.pageTotal = resData.total
  console.log('getSkuCostPageList', resData)
}
// 新增SKU成本表接口
const addSkuCostHandle = async (ruleForm, isEdit) => {
  for (const i in ruleFormSkuCost.list) {
    const skuCostItem = ruleFormSkuCost.list[i]
    jsonToNewName(skuCostItem, 'SKU名称', 'sku_name')
    jsonToNewName(skuCostItem, 'SKU价格', 'cost_price')
  }
  // 如果是编辑状态，获取当前时间
  if (isEdit) {
    const nowTime = timeToDate(new Date().getTime() / 1000).substring(2)
    const regex = /-/g
    const regex1 = / /g
    const regex2 = /:/g
    const newStr = nowTime.replace(regex, '').replace(regex1, '').replace(regex2, '')
    ruleForm.templateName = `${ruleForm.templateName.split('*')[0]}*${newStr}`
  }
  await skuCostAdd({
    template_name: ruleForm.templateName,
    content: JSON.stringify(ruleFormSkuCost.list)
  })
  getSkuCostPageList()
  baseData.SimpleDialogShow = false
}
// 编辑模板接口
const editSkuCostHandle = async (id, skuCostName) => {
  console.log('editTemplateHandle 编辑模板接口 Comedy', ruleFormSkuCost, skuCostName)
  const resData = await skuCostDetail({
    template_id: id
  })
  ruleFormSkuCost.templateId = id
  ruleFormSkuCost.templateName = skuCostName
  for (const i in resData) {
    const skuCostItem = resData[i]
    jsonToNewName(skuCostItem, 'sku_name', 'SKU名称')
    jsonToNewName(skuCostItem, 'cost_price', 'SKU价格')
    resData[i].tempId = resData[i].id
  }
  ruleFormSkuCost.list = resData
  baseData.SimpleDialogShow = true
}
// 删除模板事件 — 询问
const delSkuCostHandle = (id) => {
  ElMessageBox.confirm(
    '删除后该SKU成本表将不可再次使用，是否继续？',
    {
      confirmButtonText: '删除',
      cancelButtonText: '取消',
      type: 'warning'
    }
  ).then(() => {
    delApiHandle(id)
  }).catch(() => {
  })
}
// 删除模板事件 — 接口
const delApiHandle = async (id) => {
  await skuCostDel({
    ids: [id]
  })
  getSkuCostPageList()
}

const getAllSkuCostList = () => {
  getSkuCostList()
  getSkuCostPageList()
}

onMounted(() => {
  getAllSkuCostList()
})

watch(() => route.params, (newParams) => {
  console.log('watch 监听路由 sku', newParams?.event)
  if (newParams.event === 'addSkucost') SimpleDialogHandle('sku')
}, {
  immediate: true
})

// 点击调用子组件的上传事件
const comHandleHandleUpload = (scene) => {
  UploadExcelCom.value && UploadExcelCom.value.handleUpload('', scene)
}
// excel组件回调
const excelCall = (resData, loadingInstance) => {
  loadingInstance.close()
  // 如果是sku成本表上传，则要遵循sku成本表规范
  const callRule = ['SKU名称', 'SKU价格']
  if (resData.header.length !== 2) {
    ElMessage.error('sku成本表表头必须为"SKU名称","SKU价格"')
  } else {
    for (const i in resData.header) {
      if (callRule.indexOf(resData.header[i]) === -1) {
        ElMessage.error('sku成本表表头必须为"SKU名称","SKU价格"')
        return
      }
    }
    for (const i in resData.results) {
      resData.results[i].tempId = timeToDate(new Date().getTime() / 1000).substring(2).replace(/-/g, '').replace(/ /g, '').replace(/:/g, '') + (Math.floor(Math.random() * (999999999 - 100000000 + 1)) + 100000000).toString().slice(-9)
    }
    ruleFormSkuCost.list = resData.results
    ElMessage.success('sku成本表上传成功"')
  }
}
const skucostTableRef = ref(null)
const skucostTableObj = reactive({
  selections: [],
  selected: false
})
// 表格添加一行
const addRowHandle = () => {
  const temp = {}
  for (const i in baseData.skuCostTableHd) {
    const item = baseData.skuCostTableHd[i]
    temp[item.label] = ''
  }
  temp.tempId = timeToDate(new Date().getTime() / 1000).substring(2).replace(/-/g, '').replace(/ /g, '').replace(/:/g, '') + (Math.floor(Math.random() * (999999999 - 100000000 + 1)) + 100000000).toString().slice(-9)
  ruleFormSkuCost.list.push(temp)
  // 当前滚动容器的滚动条位置
  // 当前滚动容器的可滚动最大距离
  nextTick(() => {
    const scrollViewHeight = document.querySelector('.skucostBox .goodsFormBox .height-auto-table .el-scrollbar__view').clientHeight
    document.querySelector('.skucostBox .goodsFormBox .height-auto-table .el-scrollbar__wrap').scrollTop = scrollViewHeight
    const lastIndex = (ruleFormSkuCost.list.length - 1 > 0) ? ruleFormSkuCost.list.length - 1 : 0
    skucostTableRef.value.setCurrentRow(ruleFormSkuCost.list[lastIndex])
  })
}
// 表格选中项事件
const skucostTableRefHandle = (e) => {
  skucostTableObj.selections = []
  skucostTableObj.selected = (e.length > 0)
  if (skucostTableObj.selected) {
    for (const i in e) {
      skucostTableObj.selections.push(e[i].tempId)
    }
  }
}
// 表格选中项删除事件
const skucostTableRefDelete = () => {
  const duplicateIds = (item) => {
    return (skucostTableObj.selections.indexOf(item.tempId) !== -1)
  }
  const delResList = ruleFormSkuCost.list.filter(item => !duplicateIds(item))
  ruleFormSkuCost.list = delResList
  skucostTableObj.selected = false
  ElMessage.success('选中项删除成功')
}
</script>

<style lang="scss" >
@import '~@/styles/variables.scss';
@import '~@/styles/mixin.scss';
@import '~@/styles/report/index.scss';
.skucostBox {
  .upload-excel {
    opacity: 0 !important;
    height: 0 !important
  }
  .detailFormDialogBox {
    .card-box {
      .card-bd {
        padding-top: 12px;
      }
    }
  }
}
</style>
