import { nextTick } from 'vue'

// elementPlus滚动条缓动
export const scrollToTopTransition = (willGo, nowSite = 0, goodsFormScoll) => {
  let passedTime = 0
  const duration = 300
  const originTime = Date.now()
  // 贝塞尔缓动式由0到1
  const easeingFunction = (t) => {
    if (t < 0.5) {
      return (4 * t * t * t)
    } else {
      return ((t - 1) * (2 * t - 2) * (2 * t - 2) + 1)
    }
  }
  const _scrollToTop = () => {
    if (passedTime < duration) {
      passedTime = Date.now() - originTime
      // duration(默认300毫秒)内重复执行
      requestAnimationFrame(_scrollToTop)
      // 当前位置nowSite到预期位置willGo的真实滚动距离
      const trueDistance = (nowSite + (willGo - nowSite) * (easeingFunction(passedTime / duration)))
      goodsFormScoll.setScrollTop(trueDistance)
    }
  }
  _scrollToTop()
}

// tabs点击事件
export const tabsListHandle = (e, tabsHd, baseData, goodsFormScoll) => {
  const indexNum = Number(e.index || e)
  const willGoCard = document.querySelector(`#cardItem${indexNum}`)
  willGoCard.classList.add('willFocus')
  setTimeout(() => {
    willGoCard.classList.remove('willFocus')
  }, 2000)
  scrollToTopTransition(tabsHd.list[indexNum].offsetTop - 12, baseData.scrollSite, goodsFormScoll)
}

// tabs 监听滚动
export const doScroll = (event, tabsHd, baseData) => {
  baseData.scrollSite = event.scrollTop
  for (const i in tabsHd.list) {
    const { offsetTop, name } = tabsHd.list[i]
    if (offsetTop >= baseData.scrollSite) {
      tabsHd.currentIndex = name
      break
    }
  }
}

// 获取cradItem锚点距顶坐标
export const getCardItemSite = (tabsHd) => {
  nextTick(() => {
    const list = tabsHd.list
    for (const i in list) {
      tabsHd.list[i].offsetTop = document.querySelector(`#cardItem${i}`).offsetTop
    }
  })
}
